import { render, staticRenderFns } from "./lampConfiguration.vue?vue&type=template&id=51d75718&scoped=true"
import script from "./lampConfiguration.vue?vue&type=script&lang=js"
export * from "./lampConfiguration.vue?vue&type=script&lang=js"
import style0 from "./lampConfiguration.vue?vue&type=style&index=0&id=51d75718&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "51d75718",
  null
  
)

export default component.exports